import AiRecord from 'utils/requestManager/AiRecord';

export const getAiRecordById = (id: number) => {
  return AiRecord.get(`/api/v1/ai-record/${id}`)
}

export const getListAiRecord = async ({ billId, bookingId, positionType }: { billId: string, bookingId: string, positionType?: string }) => {
  const { data } = await AiRecord.get(`/api/v1/ai-record`, {
    params: { BillId: billId, BookingId: bookingId, positionType }
  })
  return data
}

export const getRecordIntent = () => {
  return AiRecord.get(`/api/v1/ai-record-intent`)
}

export const getListByStylist = async (billIds: string) => {
  const { data } = await AiRecord.get(`/api/v1/ai-record/list-by-stylist`, {
    params: { billIds }
  })
  return data
}

export const getListByCheckin = async (billIds: string) => {
  const { data } = await AiRecord.get(`/api/v1/ai-record/list-by-checkin`, {
    params: { billIds }
  })
  return data
}

export const getAudioReviews = async (id: number) => {
  const { data } = await AiRecord.get(`/api/v1/ai-record/${id}/reviews`)
  return data
}

export const analyzeAudioWithClaudeAi = async (id: number) => {
  const { data } = await AiRecord.get(`/api/v1/ai-record/${id}/analyze`)
  return data
}