import { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { useDoubleTap } from "use-double-tap";

const ParagraphWebview = ({
  paragraphs,
  currentTime,
  handleDoubleClick,
  isWebview,
  hasShow,
}: {
  paragraphs: any[];
  currentTime: number;
  handleDoubleClick: Function;
  isWebview: boolean;
  hasShow: boolean;
}) => {
  const formatTime = (seconds) =>
    [seconds / 60, seconds % 60]
      .map((v) => `0${Math.floor(v)}`.slice(-2))
      .join(":");
  const [lastTap, setLastTap] = useState(0);
  const paragraphRefs = useRef([]);
  const handleDoubleTap = useDoubleTap((event) => {
    const start = event.currentTarget.getAttribute("data-start");
    handleDoubleClick(parseInt(start) / 1000);
  });

  const [highlightedParagraph, setHighlightedParagraph] = useState(null);
  const [highlightWordIndex, setHighlightWordIndex] = useState("");
  const highlightedWordRef = useRef(null); // Tạo ref cho từ được highlight
  const [wordCoords, setWordCoords] = useState({ x: 0, y: 0 }); // Trạng thái để lưu tọa độ từ được highlight

  useEffect(() => {
    if (!currentTime || !paragraphs || paragraphs.length === 0) return;

    const currentTimeInMiliseconds = Math.round(currentTime * 1000);
    const currentParagraph = paragraphs.find((paragraph) => {
      return (
        currentTimeInMiliseconds >= paragraph.words[0].start &&
        currentTimeInMiliseconds <
          paragraph.words[paragraph.words.length - 1].end
      );
    });

    if (currentParagraph && currentParagraph?.words?.length) {
      const currentWordIndex = currentParagraph.words.findIndex(
        (el) =>
          currentTimeInMiliseconds >= el.start &&
          currentTimeInMiliseconds < el.end
      );
      if (currentWordIndex > 0) setHighlightWordIndex(currentWordIndex);
    }

    setHighlightedParagraph(currentParagraph);
  }, [paragraphs, currentTime]);

  useEffect(() => {
    if (highlightedWordRef.current) {
      const rect = highlightedWordRef.current.getBoundingClientRect(); // Lấy tọa độ của từ được highlight
      setWordCoords({ x: rect.x, y: rect.y }); // Lưu tọa độ vào trạng thái

      // Cuộn đến vị trí của từ được highlight
      highlightedWordRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      }); // Cuộn mượt và căn giữa từ đó trong view
    }
  }, [highlightWordIndex]); // Chỉ chạy khi highlightWordIndex thay đổi

  return (
    <div className={hasShow ? "h-72" : "hidden"}>
      {paragraphs.map((paragraph, paragraphIndex) => (
        <div key={`paragraph-${paragraphIndex}`}>
          <p
            className={`text-xs ${
              isWebview ? "text-black px-5" : "text-gray-400"
            }`}
          >
            <i>{formatTime(paragraph.words[0].start)}</i>
          </p>
          <p className={isWebview ? "px-5" : ""}>
            {paragraph.words.map((word, wordIndex) => (
              <span
                key={`word-${wordIndex}`}
                data-start={word.start}
                data-end={word.end}
                {...handleDoubleTap}
                onDoubleClick={() => handleDoubleClick(word.start)}
                ref={
                  highlightedParagraph === paragraph &&
                  highlightWordIndex == wordIndex
                    ? highlightedWordRef
                    : null
                } // Gán ref cho từ được highlight
                className={
                  highlightedParagraph === paragraph &&
                  highlightWordIndex == wordIndex
                    ? "bg-orange-500"
                    : isWebview
                    ? "text-black"
                    : "text-gray-400"
                }
              >
                {word.text}
              </span>
            ))}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ParagraphWebview;
