import { Button, RadioChangeEvent, Slider, Spin, Tabs, TabsProps } from "antd";
import {
  getAiRecordById,
  getAudioReviews,
  getRecordIntent,
} from "api/aiRecord";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import orderBy from "lodash/orderBy";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import AudioView from "pages/ai-record/PopupDetail/AudioView";
import ClaudeAiReviewResults from "pages/ai-record/PopupDetail/ClaudeAiReviewResults";
import Paragraph from "pages/ai-record/PopupDetail/Paragraph";
import AudioViewWebview from "./AudioViewWebview";
import {
  BackwardOutlined,
  CaretRightOutlined,
  ForwardFilled,
  ForwardOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import ParagraphWebview from "./ParagraphWebview";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Tab 1",
    children: "Content of Tab Pane 1",
  },
  {
    key: "2",
    label: "Tab 2",
    children: "Content of Tab Pane 2",
  },
];

const RecordMobile = () => {
  const [paragraphs, setParagraphs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [aiScriptResult, setAiScriptResult] = useState([]);
  const [isAiScriptDone, setIsAiScriptDone] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [selectTime, setSelectTime] = useState(0);
  const [audioData, setAudioData] = useState();
  const [reviewData, setReviewData] = useState([]);
  const [recordIntent, setRecordIntent] = useState<any>();
  const [billId, setBillId] = useState(0);
  const [isWavesurferReady, setIsWavesurferReady] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");
  const [audioSources, setAudioSources] = useState([]);
  const [isMemobotRunning, setIsMemobotRunning] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<any>(null);
  const onChangeAudioSource = ({ target: { value } }: RadioChangeEvent) => {
    setAudioSrc(value);
  };
  const intervalRef = useRef(null);
  const formatTime = (seconds) =>
    [seconds / 60, seconds % 60]
      .map((v) => `0${Math.floor(v)}`.slice(-2))
      .join(":");

  const handleFetchRecordIntent = async () => {
    try {
      const { data } = await getRecordIntent();
      if (data) {
        setRecordIntent(data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const { id } = useParams();

  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  async function fetchRecordData(id) {
    try {
      const { data } = await getAiRecordById(id);

      if (data.BillId) {
        setBillId(data.BillId);
      }
      if (data.AudioUrls) {
        const audios = JSON.parse(data.AudioUrls);
        setAudioSources(audios);
        setAudioSrc(audios[audios.length - 1]);
      }

      return data;
    } catch (error) {
      setError(error);
    }
  }

  function handleAiScriptData(aIScriptResult) {
    // kich ban data
    const aiScriptResultConverted = [];
    const aiScriptResult = orderBy(JSON.parse(aIScriptResult), [
      "score",
      "desc",
    ]);
    setIsAiScriptDone(!!aiScriptResult);
    if (aiScriptResult) {
      recordIntent?.forEach((element) => {
        aiScriptResultConverted.push({
          intent_display: element.Name,
          intent: element.Slug,
          sentences: [],
        });
      });

      for (let index = 0; index < aiScriptResult.length; index++) {
        const element = aiScriptResult[index];
        if (element.score > 0.7) {
          // const foundIndex = aiScriptResultConverted.findIndex((el) => el.intent === element.intent)
          // not in array
          const intentFoundIndex = aiScriptResultConverted.findIndex(
            (el) => el.intent === element.intent
          );
          aiScriptResultConverted[intentFoundIndex].sentences.unshift({
            score: element.score,
            content: element.sent,
          });
        }
      }
    }
    setAiScriptResult(aiScriptResultConverted);
  }

  const fetchData = async (aiRecordId) => {
    const recordData = await fetchRecordData(aiRecordId);
    setParagraphs(JSON.parse(recordData.MemoResult));
    handleAiScriptData(recordData.AIScriptResult);
    setAudioData(recordData);
    setLoading(false);
  };

  function runAudio2Text() {
    setIsMemobotRunning(true);
  }

  useEffect(() => {
    if (isMemobotRunning) {
      const intervalId = setInterval(() => {
        fetchData(id);
      }, 5000);
      intervalRef.current = intervalId;
    } else if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
  }, [isMemobotRunning]);

  useEffect(() => {
    handleFetchRecordIntent();
  }, []);

  useEffect(() => {
    if (id && recordIntent) {
      fetchData(id);

      getAudioReviews(parseInt(id)).then((rs) => {
        setReviewData(rs);
      });
    }
  }, [id, recordIntent]);

  useEffect(() => {
    if (audioRef?.current) {
      setIsPlaying(audioRef?.current?.isPlaying);
    }
  }, [audioRef?.current?.isPlaying, isPlaying]);

  const handleReady = useCallback(() => {
    if (audioRef?.current?.wavesurfer) {
      setDuration(audioRef?.current?.wavesurfer.getDuration());
    }
  }, [audioRef]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      audioRef.current.onPlayPause();
    }
  };

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.forwardButton();
    }
  };

  const handleBackward = () => {
    if (audioRef.current) {
      audioRef.current.backButton();
    }
  };

  const onChange = (key: string) => {
    console.log(key);
    setSelectedTab(parseInt(key));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <Spin tip="Đang xử lý dữ liệu..." spinning={loading}>
      <div className="w-full h-screen bg-white">
        <div className="flex justify-center items-center h-3/6">
          <div className="w-3/4 h-3/4 flex justify-center items-center">
            <div className="w-full overflow-y-auto ">
              <AudioViewWebview
                onReady={handleReady}
                hasShow={selectedTab === 1}
                ref={audioRef}
                updateCurrentTime={setCurrentTime}
                audioSrc={audioSrc}
                selectTime={selectTime}
                audioSources={audioSources}
                onChangeAudioSource={onChangeAudioSource}
              ></AudioViewWebview>
              {paragraphs ? (
                <ParagraphWebview
                  hasShow={selectedTab === 2}
                  isWebview={true}
                  paragraphs={paragraphs}
                  currentTime={currentTime}
                  handleDoubleClick={setSelectTime}
                ></ParagraphWebview>
              ) : (
                <Button
                  type="primary"
                  onClick={runAudio2Text}
                  className={selectedTab !== 2 ? "hidden" : ""}
                  loading={isMemobotRunning}
                >
                  {isMemobotRunning
                    ? "Đang chuyển đổi"
                    : "Chuyển đổi audio thành văn bản"}
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-center space-x-4 my-4">
          <Tabs
            size="large"
            tabPosition={"bottom"}
            items={[
              {
                key: "1",
                label: "Audio",
              },
              {
                key: "2",
                label: "Paragraph",
              },
            ]}
            onChange={onChange}
          />
          {/* <Button
            type={selectedTab === 1 ? "primary" : "default"}
            onClick={() => setSelectedTab(1)}
          >
            Audio
          </Button>
          <Button
            type={selectedTab === 2 ? "primary" : "default"}
            onClick={() => setSelectedTab(2)}
          >
            Paragraph
          </Button> */}
        </div>
        <div className="flex justify-center items-center">
          <div className="flex justify-between w-full px-5 text-black text-sm">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>

        <div className=" w-full px-5 ">
          <Slider
            value={currentTime}
            min={0}
            max={duration}
            step={1}
            disabled={false}
            onChange={(value) => {
              setSelectTime(value);
            }}
          />
        </div>

        <div className="flex justify-center items-center mt-4 space-x-8">
          <button
            className="bg-gray-800 w-12 h-12 flex justify-center items-center rounded-full"
            onClick={handleBackward}
          >
            <BackwardOutlined className="text-blue-300 hover:text-blue-600 text-3xl" />
          </button>
          <button
            className="bg-gray-800 w-16 h-16 flex justify-center items-center rounded-full"
            onClick={handlePlayPause}
          >
            {isPlaying ? (
              <PauseOutlined className="text-blue-300 text-4xl" />
            ) : (
              <CaretRightOutlined className="text-blue-300 text-4xl" />
            )}
          </button>
          <button
            className="bg-gray-800 w-12 h-12 flex justify-center items-center rounded-full"
            onClick={handleForward}
          >
            <span className="material-icons text-white">
              <ForwardFilled className="text-blue-300 hover:text-blue-600 text-3xl" />
            </span>
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default RecordMobile;
